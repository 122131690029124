import React from "react";
import {ServiceDto} from "../../../api-client/model";
import {Chip, Stack, Typography} from "@mui/material";

type Props = {
  service: ServiceDto;
};

export const ApplicationTitle: React.FC<Props> = ({ service }) => {

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ alignItems: "center", pb: 3 }}>
        <Typography variant="h4">{service.name}</Typography>
        <Chip label={service.application} />
      </Stack>
    </>
  );

};
