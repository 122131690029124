/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { LogEntryDto, GetLogsParams } from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getLogs = (
  params?: GetLogsParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<LogEntryDto[]>(
    { url: `/api/v1/logs`, method: "get", params, signal },
    options
  );
};

export const getGetLogsQueryKey = (params?: GetLogsParams) => [
  `/api/v1/logs`,
  ...(params ? [params] : []),
];

export type GetLogsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLogs>>
>;
export type GetLogsQueryError = unknown;

export const useGetLogs = <
  TData = Awaited<ReturnType<typeof getLogs>>,
  TError = unknown
>(
  params?: GetLogsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getLogs>>, TError, TData>;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLogsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLogs>>> = ({
    signal,
  }) => getLogs(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLogs>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getLogMessage = (
  messageId: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<LogEntryDto>(
    { url: `/api/v1/logs/${messageId}`, method: "get", signal },
    options
  );
};

export const getGetLogMessageQueryKey = (messageId: string) => [
  `/api/v1/logs/${messageId}`,
];

export type GetLogMessageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLogMessage>>
>;
export type GetLogMessageQueryError = unknown;

export const useGetLogMessage = <
  TData = Awaited<ReturnType<typeof getLogMessage>>,
  TError = unknown
>(
  messageId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLogMessage>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLogMessageQueryKey(messageId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLogMessage>>> = ({
    signal,
  }) => getLogMessage(messageId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getLogMessage>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!messageId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
