/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  EmorficCustomerQueueMessageDto,
  EmorficCustomerQueueDto,
  GetEmorficCustomerQueueMessagesParams,
  EmorficOrderQueueMessageDto,
  EmorficOrderQueueDto,
  GetEmorficOrderQueueMessagesParams,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const emorficCustomerQueueRetryMessage = (
  client: string,
  emorficApplication: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<EmorficCustomerQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${emorficApplication}/customer-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type EmorficCustomerQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof emorficCustomerQueueRetryMessage>>
>;

export type EmorficCustomerQueueRetryMessageMutationError = unknown;

export const useEmorficCustomerQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof emorficCustomerQueueRetryMessage>>,
    TError,
    { client: string; emorficApplication: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof emorficCustomerQueueRetryMessage>>,
    { client: string; emorficApplication: string; messageId: string }
  > = (props) => {
    const { client, emorficApplication, messageId } = props ?? {};

    return emorficCustomerQueueRetryMessage(
      client,
      emorficApplication,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof emorficCustomerQueueRetryMessage>>,
    TError,
    { client: string; emorficApplication: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getEmorficCustomerQueueMessages = (
  client: string,
  emorficApplication: string,
  params?: GetEmorficCustomerQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<EmorficCustomerQueueDto>(
    {
      url: `/api/v1/clients/${client}/${emorficApplication}/customer-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetEmorficCustomerQueueMessagesQueryKey = (
  client: string,
  emorficApplication: string,
  params?: GetEmorficCustomerQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${emorficApplication}/customer-queue`,
  ...(params ? [params] : []),
];

export type GetEmorficCustomerQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEmorficCustomerQueueMessages>>
>;
export type GetEmorficCustomerQueueMessagesQueryError = unknown;

export const useGetEmorficCustomerQueueMessages = <
  TData = Awaited<ReturnType<typeof getEmorficCustomerQueueMessages>>,
  TError = unknown
>(
  client: string,
  emorficApplication: string,
  params?: GetEmorficCustomerQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEmorficCustomerQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetEmorficCustomerQueueMessagesQueryKey(
      client,
      emorficApplication,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEmorficCustomerQueueMessages>>
  > = ({ signal }) =>
    getEmorficCustomerQueueMessages(
      client,
      emorficApplication,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getEmorficCustomerQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && emorficApplication),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const emorficOrderQueueRetryMessage = (
  client: string,
  emorficApplication: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<EmorficOrderQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${emorficApplication}/order-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type EmorficOrderQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof emorficOrderQueueRetryMessage>>
>;

export type EmorficOrderQueueRetryMessageMutationError = unknown;

export const useEmorficOrderQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof emorficOrderQueueRetryMessage>>,
    TError,
    { client: string; emorficApplication: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof emorficOrderQueueRetryMessage>>,
    { client: string; emorficApplication: string; messageId: string }
  > = (props) => {
    const { client, emorficApplication, messageId } = props ?? {};

    return emorficOrderQueueRetryMessage(
      client,
      emorficApplication,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof emorficOrderQueueRetryMessage>>,
    TError,
    { client: string; emorficApplication: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getEmorficOrderQueueMessages = (
  client: string,
  emorficApplication: string,
  params?: GetEmorficOrderQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<EmorficOrderQueueDto>(
    {
      url: `/api/v1/clients/${client}/${emorficApplication}/order-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetEmorficOrderQueueMessagesQueryKey = (
  client: string,
  emorficApplication: string,
  params?: GetEmorficOrderQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${emorficApplication}/order-queue`,
  ...(params ? [params] : []),
];

export type GetEmorficOrderQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEmorficOrderQueueMessages>>
>;
export type GetEmorficOrderQueueMessagesQueryError = unknown;

export const useGetEmorficOrderQueueMessages = <
  TData = Awaited<ReturnType<typeof getEmorficOrderQueueMessages>>,
  TError = unknown
>(
  client: string,
  emorficApplication: string,
  params?: GetEmorficOrderQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEmorficOrderQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetEmorficOrderQueueMessagesQueryKey(client, emorficApplication, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEmorficOrderQueueMessages>>
  > = ({ signal }) =>
    getEmorficOrderQueueMessages(
      client,
      emorficApplication,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getEmorficOrderQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && emorficApplication),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
