/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  LokiaDocumentQueueMessageDto,
  LokiaDocumentQueueDto,
  GetLokiaDocumentQueueMessagesParams,
  LokiaNotificationQueueMessageDto,
  LokiaNotificationQueueDto,
  GetLokiaNotificationQueueMessagesParams,
  LokiaProductQueueMessageDto,
  LokiaProductQueueDto,
  GetLokiaProductQueueMessagesParams,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const lokiaDocumentQueueRetryMessage = (
  client: string,
  lokiaApplication: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<LokiaDocumentQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${lokiaApplication}/document-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type LokiaDocumentQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof lokiaDocumentQueueRetryMessage>>
>;

export type LokiaDocumentQueueRetryMessageMutationError = unknown;

export const useLokiaDocumentQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lokiaDocumentQueueRetryMessage>>,
    TError,
    { client: string; lokiaApplication: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lokiaDocumentQueueRetryMessage>>,
    { client: string; lokiaApplication: string; messageId: string }
  > = (props) => {
    const { client, lokiaApplication, messageId } = props ?? {};

    return lokiaDocumentQueueRetryMessage(
      client,
      lokiaApplication,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof lokiaDocumentQueueRetryMessage>>,
    TError,
    { client: string; lokiaApplication: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getLokiaDocumentQueueMessages = (
  client: string,
  lokiaApplication: string,
  params?: GetLokiaDocumentQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<LokiaDocumentQueueDto>(
    {
      url: `/api/v1/clients/${client}/${lokiaApplication}/document-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetLokiaDocumentQueueMessagesQueryKey = (
  client: string,
  lokiaApplication: string,
  params?: GetLokiaDocumentQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${lokiaApplication}/document-queue`,
  ...(params ? [params] : []),
];

export type GetLokiaDocumentQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLokiaDocumentQueueMessages>>
>;
export type GetLokiaDocumentQueueMessagesQueryError = unknown;

export const useGetLokiaDocumentQueueMessages = <
  TData = Awaited<ReturnType<typeof getLokiaDocumentQueueMessages>>,
  TError = unknown
>(
  client: string,
  lokiaApplication: string,
  params?: GetLokiaDocumentQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLokiaDocumentQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLokiaDocumentQueueMessagesQueryKey(client, lokiaApplication, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLokiaDocumentQueueMessages>>
  > = ({ signal }) =>
    getLokiaDocumentQueueMessages(
      client,
      lokiaApplication,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getLokiaDocumentQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && lokiaApplication),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const lokiaNotificationQueueRetryMessage = (
  client: string,
  lokiaApplication: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<LokiaNotificationQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${lokiaApplication}/notification-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type LokiaNotificationQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof lokiaNotificationQueueRetryMessage>>
>;

export type LokiaNotificationQueueRetryMessageMutationError = unknown;

export const useLokiaNotificationQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lokiaNotificationQueueRetryMessage>>,
    TError,
    { client: string; lokiaApplication: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lokiaNotificationQueueRetryMessage>>,
    { client: string; lokiaApplication: string; messageId: string }
  > = (props) => {
    const { client, lokiaApplication, messageId } = props ?? {};

    return lokiaNotificationQueueRetryMessage(
      client,
      lokiaApplication,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof lokiaNotificationQueueRetryMessage>>,
    TError,
    { client: string; lokiaApplication: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getLokiaNotificationQueueMessages = (
  client: string,
  lokiaApplication: string,
  params?: GetLokiaNotificationQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<LokiaNotificationQueueDto>(
    {
      url: `/api/v1/clients/${client}/${lokiaApplication}/notification-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetLokiaNotificationQueueMessagesQueryKey = (
  client: string,
  lokiaApplication: string,
  params?: GetLokiaNotificationQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${lokiaApplication}/notification-queue`,
  ...(params ? [params] : []),
];

export type GetLokiaNotificationQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLokiaNotificationQueueMessages>>
>;
export type GetLokiaNotificationQueueMessagesQueryError = unknown;

export const useGetLokiaNotificationQueueMessages = <
  TData = Awaited<ReturnType<typeof getLokiaNotificationQueueMessages>>,
  TError = unknown
>(
  client: string,
  lokiaApplication: string,
  params?: GetLokiaNotificationQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLokiaNotificationQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLokiaNotificationQueueMessagesQueryKey(
      client,
      lokiaApplication,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLokiaNotificationQueueMessages>>
  > = ({ signal }) =>
    getLokiaNotificationQueueMessages(
      client,
      lokiaApplication,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getLokiaNotificationQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && lokiaApplication),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const lokiaProductQueueRetryMessage = (
  client: string,
  lokiaApplication: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<LokiaProductQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${lokiaApplication}/product-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type LokiaProductQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof lokiaProductQueueRetryMessage>>
>;

export type LokiaProductQueueRetryMessageMutationError = unknown;

export const useLokiaProductQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lokiaProductQueueRetryMessage>>,
    TError,
    { client: string; lokiaApplication: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lokiaProductQueueRetryMessage>>,
    { client: string; lokiaApplication: string; messageId: string }
  > = (props) => {
    const { client, lokiaApplication, messageId } = props ?? {};

    return lokiaProductQueueRetryMessage(
      client,
      lokiaApplication,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof lokiaProductQueueRetryMessage>>,
    TError,
    { client: string; lokiaApplication: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getLokiaProductQueueMessages = (
  client: string,
  lokiaApplication: string,
  params?: GetLokiaProductQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<LokiaProductQueueDto>(
    {
      url: `/api/v1/clients/${client}/${lokiaApplication}/product-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetLokiaProductQueueMessagesQueryKey = (
  client: string,
  lokiaApplication: string,
  params?: GetLokiaProductQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${lokiaApplication}/product-queue`,
  ...(params ? [params] : []),
];

export type GetLokiaProductQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLokiaProductQueueMessages>>
>;
export type GetLokiaProductQueueMessagesQueryError = unknown;

export const useGetLokiaProductQueueMessages = <
  TData = Awaited<ReturnType<typeof getLokiaProductQueueMessages>>,
  TError = unknown
>(
  client: string,
  lokiaApplication: string,
  params?: GetLokiaProductQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLokiaProductQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLokiaProductQueueMessagesQueryKey(client, lokiaApplication, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLokiaProductQueueMessages>>
  > = ({ signal }) =>
    getLokiaProductQueueMessages(
      client,
      lokiaApplication,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getLokiaProductQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && lokiaApplication),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
