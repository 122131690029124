import React, {useState} from "react";
import {
  GetLokiaProductQueueMessagesParams,
  LokiaProductQueueMessageDto,
} from "../../../api-client/model";
import {DEFAULT_FILTER} from "../../common/queue/SmartMessageQueue";
import {
  useGetLokiaProductQueueMessages,
  useLokiaProductQueueRetryMessage,
} from "../../../api-client/lokia";
import {MessageQueue} from "../../common/queue/MessageQueue";

type Props = {
  clientId: string,
  application: string,
};

export const LokiaProductQueue: React.FC<Props> = ({clientId, application}) => {

  const [filter, setFilter] =
    useState<GetLokiaProductQueueMessagesParams>(DEFAULT_FILTER);
  const queueQuery = useGetLokiaProductQueueMessages(
    clientId,
    application,
    filter
  );

  const retryMutation = useLokiaProductQueueRetryMessage();

  function retry(message: LokiaProductQueueMessageDto) {
    return retryMutation.mutateAsync({
      client: clientId,
      lokiaApplication: application,
      messageId: message.id,
    });
  }

  return (
    <>
      <MessageQueue
        title={'Fronta zpráv produktů'}
        clientId={clientId}
        messages={queueQuery.data?.items ?? []}
        refetch={queueQuery.refetch}
        isLoading={queueQuery.isLoading}
        count={queueQuery.data?.total}
        retryMessage={retry}
        filter={filter}
        onFilter={(filter) =>
          setFilter((current) => ({...current, ...filter}))
        }
      ></MessageQueue>
    </>
  );
};
