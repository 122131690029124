import {
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import React, {useEffect} from "react";
import {JobDto} from "../api-client/model";
import {Link} from "@mui/material";
import {Link as LinkRouter} from "react-router-dom";
import {CheckCircleOutline, ErrorOutline} from "@mui/icons-material";
import {ElapsedTime} from "../utils/elapsed";
import {dateTimeFormat} from "../utils/date";
import {ConfirmDialog} from "../ui/ConfirmDialog";
import {useKillJob} from "../api-client/jobs";

type Props = {
  jobs: JobDto[];
  showPid?: boolean;
}

export const JobsList: React.FC<Props> = ({ jobs, showPid }) => {
  const [jobToTerminate, setJobToTerminate] = React.useState<JobDto | null>(null);

  const terminateMutation = useKillJob();

  async function terminate(jobToTerminate: JobDto) {
    setJobToTerminate(null);
    await terminateMutation.mutateAsync({ params: { jobId: jobToTerminate.id } });
  }

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Job</TableCell>
            {showPid && <TableCell>PID</TableCell>}
            <TableCell>Started</TableCell>
            <TableCell align="right">Elapsed</TableCell>
            {showPid && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map(job => (
            <TableRow key={job.id} hover>
              <TableCell>
                <Box sx={{ display: 'flex' }}>
                  {job.failed && <ErrorOutline color="error" />}
                  {job.finishedAt && !job.failed && <CheckCircleOutline color="success" />}
                </Box>
              </TableCell>
              <TableCell>{job.id}</TableCell>
              <TableCell>
                <Link
                  component={LinkRouter}
                  to={`/clients/${job.client.id}`}
                  color="secondary"
                >{job.client.name}</Link>
              </TableCell>
              <TableCell>
                <Link
                  component={LinkRouter}
                  to={`/clients/${job.client.id}/scheduler`}
                  color="secondary"
                >{job.schedulerJob?.name}</Link>
              </TableCell>
              {showPid && <TableCell>{job.pid}</TableCell>}
              <TableCell>{dateTimeFormat(job.startedAt)}</TableCell>
              <TableCell align="right">
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                  <ElapsedTime
                    startDate={job.startedAt}
                    endDate={job.finishedAt}
                  />

                  {!job.finishedAt && (
                    <Box sx={{ marginLeft: 1 }}>
                      <JobProgress job={job} />
                    </Box>
                  )}
                </Box>
              </TableCell>
              {showPid && (
                <TableCell sx={{ py: 0, width: 85 }}>
                  <Button variant="text" size="small" color="error" onClick={() => setJobToTerminate(job)}>Terminate</Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ConfirmDialog
        open={jobToTerminate != null}
        onCancel={() => setJobToTerminate(null)}
        onConfirm={() => terminate(jobToTerminate!)}
        confirmLabel="Terminate"
        danger
        title="Terminate job?"
      >Job {jobToTerminate?.id} will be termined.</ConfirmDialog>
    </>
  );
};

const JobProgress: React.FC<{ job: JobDto }> = ({ job }) => {
  const averageRuntimeSeconds = job.schedulerJob?.stats?.avgDuration ?? 0;

  function getProgress() {
    const elapsedSeconds = (new Date().getTime() - new Date(job.startedAt).getTime()) / 1000;
    return Math.min(100, (elapsedSeconds / averageRuntimeSeconds * 100));
  }

  const [progress, setProgress] = React.useState(getProgress());

  useEffect(() => {
    const interval = setInterval(() => {
      if (job.finishedAt) {
        clearInterval(interval);
        return;
      }

      const newProgress = getProgress();

      if (newProgress !== progress) {
        setProgress(newProgress);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <LinearProgress variant="determinate" color="secondary" value={progress} sx={{ width: 90, height: 10, borderRadius: 3 }} />
  );
}
