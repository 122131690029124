import React, {useState} from "react";
import {
  GetPohoda2TransactionQueueMessagesParams,
  Pohoda2TransactionQueueMessageDto,
} from "../../../api-client/model";
import {DEFAULT_FILTER, SmartMessageQueue} from "../../common/queue/SmartMessageQueue";
import {useGetPohoda2TransactionQueueMessages, usePohoda2TransactionQueueRetryMessage} from "../../../api-client/pohoda2";
import {XmlDialog, XmlDialogData} from "../../../components/XmlDialog";
import {Button} from "@mui/material";

type Props = {
  clientId: string,
  application: string,
};

export const Pohoda2TransactionQueue: React.FC<Props> = ({clientId, application}) => {

  const [xmlDialogData, setXmlDialogData] = useState<XmlDialogData | null>();

  const [filter, setFilter] =
    useState<GetPohoda2TransactionQueueMessagesParams>(DEFAULT_FILTER);
  const queueQuery = useGetPohoda2TransactionQueueMessages(
    clientId,
    application,
    filter
  );

  const retryMutation = usePohoda2TransactionQueueRetryMessage();

  function retry(message: Pohoda2TransactionQueueMessageDto) {
    return retryMutation.mutateAsync({
      client: clientId,
      pohoda2Application: application,
      messageId: message.id,
    });
  }

  return (
    <>
      {xmlDialogData && <XmlDialog open={true} onClose={() => setXmlDialogData(null)} data={xmlDialogData} />}

      <SmartMessageQueue
        title={'Fronta zpráv skladových pohybů'}
        clientId={clientId}
        messages={queueQuery.data?.items ?? []}
        refetch={queueQuery.refetch}
        isLoading={queueQuery.isLoading}
        count={queueQuery.data?.total}
        retryMessage={retry}
        filter={filter}
        onFilter={(filter) =>
          setFilter((current) => ({...current, ...filter}))
        }
        extraColumns={[
          {
            name: "Pohoda ID",
            render: (x) => x.pohodaId || "-",
            sx: {py: 0},
          },
          {
            name: "Request",
            render: (x) =>
              x.request && (
                <Button size="small"
                        onClick={() => setXmlDialogData({xml: x.request!, exportFilename: x.sourceId + '-transactionRequest'})}>
                  zobrazit
                </Button>
              ),
            sx: {py: 0},
          },
          {
            name: "Response",
            render: (x) =>
              x.response && (
                <Button size="small"
                        onClick={() => setXmlDialogData({xml: x.response!, exportFilename: x.sourceId + '-transactionResponse'})}>
                  zobrazit
                </Button>
              ),
            sx: {py: 0},
          },
        ]}
      ></SmartMessageQueue>

    </>
  );
};
