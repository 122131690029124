import React, {useState} from "react";
import {
  GetLokiaNotificationQueueMessagesParams,
  LokiaNotificationQueueMessageDto,
} from "../../../api-client/model";
import {DEFAULT_FILTER, SmartMessageQueue} from "../../common/queue/SmartMessageQueue";
import {
  useGetLokiaNotificationQueueMessages,
  useLokiaNotificationQueueRetryMessage
} from "../../../api-client/lokia";

type Props = {
  clientId: string,
  application: string,
};

export const LokiaNotificationQueue: React.FC<Props> = ({clientId, application}) => {

  const [filter, setFilter] =
    useState<GetLokiaNotificationQueueMessagesParams>(DEFAULT_FILTER);
  const queueQuery = useGetLokiaNotificationQueueMessages(
    clientId,
    application,
    filter
  );

  const retryMutation = useLokiaNotificationQueueRetryMessage();

  function retry(message: LokiaNotificationQueueMessageDto) {
    return retryMutation.mutateAsync({
      client: clientId,
      lokiaApplication: application,
      messageId: message.id,
    });
  }

  return (
    <>

      <SmartMessageQueue
        title={'Fronta zpráv notifikací'}
        clientId={clientId}
        messages={queueQuery.data?.items ?? []}
        refetch={queueQuery.refetch}
        isLoading={queueQuery.isLoading}
        count={queueQuery.data?.total}
        retryMessage={retry}
        filter={filter}
        onFilter={(filter) =>
          setFilter((current) => ({...current, ...filter}))
        }
      ></SmartMessageQueue>

    </>
  );
};
