import {
  AppBar as Bar, Box,
  Button,
  Menu,
  MenuItem, Tab, Tabs,
  Toolbar,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import React, {useMemo, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { useLogout } from "../api-client/users";
import { useAuthentication } from "../auth";
import {ClientSelectDialog} from "./ClientSelectDialog";

const MENU = [
  {
    label: 'Dashboard',
    link: '/',
  },
  {
    label: 'Clients',
    link: '/clients',
  },
  {
    label: 'Jobs',
    link: '/jobs',
  },
  {
    label: 'Logs',
    link: '/logs',
  },
  {
    label: 'Users',
    link: '/users',
  }
]

export function AppBar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const auth = useAuthentication();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [clientSelectOpen, setClientSelectOpen] = useState(false);

  const logoutMutation = useLogout({
    mutation: {
      onSuccess: () => {
        navigate("/login");
        auth.refresh();
      },
    },
  });

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentTab = useMemo(() => {
    const path = pathname.split("/")[1];

    return MENU.some(x => x.link.replace('/', '') === path) ? path : false;
  }, [pathname]);

  return (
    <Bar position="static">
      <Toolbar variant="dense">
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img src="/logo.svg" alt="logos" style={{ height: 20 }} />

          <Button
            color="secondary"
            variant="outlined"
            sx={{ ml: 3 }}
            onClick={() => setClientSelectOpen(true)}
          >Manage client</Button>

          <ClientSelectDialog open={clientSelectOpen} onClose={() => setClientSelectOpen(false)} />

          {auth.isSuperAdmin && (
            <Tabs
              value={currentTab}
              textColor="inherit"
              indicatorColor="secondary"
              scrollButtons
              sx={{ ml: 3 }}
            >
              {MENU.map((item) => (
                <Tab
                  key={item.link}
                  label={item.label}
                  color="inherit"
                  component={Link}
                  to={item.link}
                  value={item.link.replace('/', '')}
                />
              ))}
            </Tabs>
          )}
        </Box>

        <div>
          <Button
            size="large"
            onClick={handleMenu}
            color="inherit"
            startIcon={<AccountCircle />}
          >
            {auth.user?.name}
          </Button>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate("/user/change-password");
                handleClose();
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem onClick={() => logoutMutation.mutate()}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </Bar>
  );
}
