import React, {useState} from "react";
import {
  GetEmorficCustomerQueueMessagesParams,
  EmorficCustomerQueueMessageDto,
} from "../../../api-client/model";
import {DEFAULT_FILTER} from "../../common/queue/SmartMessageQueue";
import {
  useGetEmorficCustomerQueueMessages,
  useEmorficCustomerQueueRetryMessage,
} from "../../../api-client/emorfic";
import {MessageQueue} from "../../common/queue/MessageQueue";

type Props = {
  clientId: string,
  application: string,
};

export const EmorficCustomerQueue: React.FC<Props> = ({clientId, application}) => {

  const [filter, setFilter] =
    useState<GetEmorficCustomerQueueMessagesParams>(DEFAULT_FILTER);
  const queueQuery = useGetEmorficCustomerQueueMessages(
    clientId,
    application,
    filter
  );

  const retryMutation = useEmorficCustomerQueueRetryMessage();

  function retry(message: EmorficCustomerQueueMessageDto) {
    return retryMutation.mutateAsync({
      client: clientId,
      emorficApplication: application,
      messageId: message.id,
    });
  }

  return (
    <>
      <MessageQueue
        title={'Fronta zpráv zákazníků'}
        clientId={clientId}
        messages={queueQuery.data?.items ?? []}
        refetch={queueQuery.refetch}
        isLoading={queueQuery.isLoading}
        count={queueQuery.data?.total}
        retryMessage={retry}
        filter={filter}
        onFilter={(filter) =>
          setFilter((current) => ({...current, ...filter}))
        }
        extraColumns={[
          {
            name: "Operace",
            render: (x) => x.operation || "-",
            sx: {py: 0},
          },
        ]}
      ></MessageQueue>
    </>
  );
};
