import React, {useState} from "react";

type Props = {
  text: string;
  maxLength: number;
};

export const TextExpand: React.FC<Props> = ({ text, maxLength }) => {

  const [showFull, setShowFull] = useState(false);

  const visibleText = (showFull || text.length <= maxLength) ? text : text.substring(0, maxLength) + "...";

  return (
    <span onClick={() => setShowFull(!showFull)}>{visibleText}</span>
  );

};
