import {useParams} from "react-router-dom";
import {useGetLogMessage} from "../api-client/logs";
import React from "react";
import {Chip, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import {LogLevelColor} from "./LogLevel";
import {dateTimeFormat} from "../utils/date";

export const LogMessage = () => {
  const { id } = useParams<{ id: string }>();
  const messageQuery = useGetLogMessage(id ?? '');
  const message = messageQuery.data;

  return (
    <Container sx={{ p: 4 }}>
      {message && (
        <>
          <Typography variant="h5" gutterBottom>{message.message.substring(0, 100)}</Typography>

          <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
            <Chip label={<><strong>Level</strong> <Typography sx={{ display: 'inline' }} fontSize="small" color={LogLevelColor[message.level]}>{message.level}</Typography></>} size="small" />
            <Chip label={<><strong>MessageId</strong> {id}</>} size="small" />
            <Chip label={<><strong>Client</strong> {message.client}</>} size="small" />
            <Chip label={<><strong>Context</strong> {message.context}</>} size="small" />
            <Chip label={dateTimeFormat(message.createdAt)} size="small" />
          </Stack>

          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Paper sx={{ p: 3 }}>
              <Typography variant="subtitle2" gutterBottom>Message</Typography>

              <pre style={{ fontSize: 10, whiteSpace: 'break-spaces' }}>{message.message}</pre>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="subtitle2" gutterBottom>Extra</Typography>

                <pre style={{ fontSize: 10, whiteSpace: 'break-spaces' }}>{JSON.stringify(message.data, null, 2)}</pre>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}
