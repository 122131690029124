import React, {useState} from "react";
import {
  BusinessCentralOrderQueueMessageDto,
  BusinessCentralOrderQueueMessageDtoAllOfRequest,
  BusinessCentralOrderQueueMessageDtoAllOfResponse,
  GetBusinessCentralOrderQueueMessagesParams,
} from "../../../api-client/model";
import {DEFAULT_FILTER, SmartMessageQueue} from "../../common/queue/SmartMessageQueue";
import {
  useBusinessCentralOrderQueueRetryMessage,
  useGetBusinessCentralOrderQueueMessages
} from "../../../api-client/business-central";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

type Props = {
  clientId: string,
  application: string
};

type JsonDialogData = {
  json: BusinessCentralOrderQueueMessageDtoAllOfRequest | BusinessCentralOrderQueueMessageDtoAllOfResponse | null,
  exportFilename: string
}

export const BusinessCentralOrderQueue: React.FC<Props> = ({clientId, application}) => {

  const [data, setShowJson] = useState<JsonDialogData | null>(
    null
  );

  const [filter, setFilter] =
    useState<GetBusinessCentralOrderQueueMessagesParams>(DEFAULT_FILTER);
  const queueQuery = useGetBusinessCentralOrderQueueMessages(
    clientId,
    application,
    filter
  );

  const retryMutation = useBusinessCentralOrderQueueRetryMessage();

  function retry(message: BusinessCentralOrderQueueMessageDto) {
    return retryMutation.mutateAsync({
      client: clientId,
      application: application,
      messageId: message.id,
    });
  }

  return (
    <>
      <Dialog open={data != null}>
        <DialogTitle>JSON</DialogTitle>
        <DialogContent>
          {(
            <pre>
              <code style={{fontSize: 12}}>
                {JSON.stringify(data?.json, null, 4)}
              </code>
            </pre>
          )}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'space-between'}}>
          <Button href={`data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(data?.json, null, 4)
          )}`} download={data?.exportFilename + `.json`}>Stáhnout</Button>
          <Button onClick={() => setShowJson(null)}>Zavřít</Button>
        </DialogActions>
      </Dialog>

      <SmartMessageQueue
        title={'Fronta zpráv objednávek'}
        clientId={clientId}
        messages={queueQuery.data?.items ?? []}
        refetch={queueQuery.refetch}
        isLoading={queueQuery.isLoading}
        count={queueQuery.data?.total}
        retryMessage={retry}
        filter={filter}
        onFilter={(filter) =>
          setFilter((current) => ({...current, ...filter}))
        }
        extraColumns={[
          {
            name: "Request",
            render: (x) =>
              x.request && (
                <Button size="small"
                        onClick={() => setShowJson({json: x.request!, exportFilename: x.sourceId + '-orderRequest'})}>
                  zobrazit
                </Button>
              ),
            sx: {py: 0},
          },
          {
            name: "Response",
            render: (x) =>
              x.response && (
                <Button size="small"
                        onClick={() => setShowJson({json: x.response!, exportFilename: x.sourceId + 'orderResponse'})}>
                  zobrazit
                </Button>
              ),
            sx: {py: 0},
          },
        ]}
      ></SmartMessageQueue>

    </>
  );
};
