import React, { useState } from "react";
import {
  useGetSfOrderQueueMessages,
  useSfRetryMessage,
} from "../../../api-client/super-faktura";
import { useParams } from "react-router-dom";
import {
  GetSfOrderQueueMessagesParams,
  SfOrderQueueMessageDto,
} from "../../../api-client/model";
import { DEFAULT_FILTER, SmartMessageQueue } from "../../common/queue/SmartMessageQueue";

export const SfOrderQueue: React.FC = () => {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;

  const [filter, setFilter] =
    useState<GetSfOrderQueueMessagesParams>(DEFAULT_FILTER);
  const queueQuery = useGetSfOrderQueueMessages(params.clientId!, filter);

  const retryMutation = useSfRetryMessage();

  function retry(message: SfOrderQueueMessageDto) {
    return retryMutation.mutateAsync({
      client: clientId,
      messageId: message.id,
    });
  }

  return (
    <SmartMessageQueue
      clientId={clientId}
      messages={queueQuery.data?.items ?? []}
      refetch={queueQuery.refetch}
      isLoading={queueQuery.isLoading}
      count={queueQuery.data?.total}
      retryMessage={retry}
      filter={filter}
      onFilter={(filter) => setFilter((current) => ({ ...current, ...filter }))}
    ></SmartMessageQueue>
  );
};
