/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  ClientCircuitBreakerDto,
  ChangeClientCircuitBreakerStateDto,
  ClientDto,
  GetClientsParams,
  ClientCreateDto,
  ClientUpdateDto,
  SchedulerToggleDto,
  ClientConfigDto,
  UserDto,
  AddClientUserDto,
  ClientStorageInfoDto,
  DbTableSizeDto,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getClientCircuitBreakers = (
  id: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<ClientCircuitBreakerDto[]>(
    { url: `/api/v1/clients/${id}/circuit-breakers`, method: "get", signal },
    options
  );
};

export const getGetClientCircuitBreakersQueryKey = (id: string) => [
  `/api/v1/clients/${id}/circuit-breakers`,
];

export type GetClientCircuitBreakersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClientCircuitBreakers>>
>;
export type GetClientCircuitBreakersQueryError = unknown;

export const useGetClientCircuitBreakers = <
  TData = Awaited<ReturnType<typeof getClientCircuitBreakers>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClientCircuitBreakers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetClientCircuitBreakersQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getClientCircuitBreakers>>
  > = ({ signal }) => getClientCircuitBreakers(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getClientCircuitBreakers>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const setClientCircuitBreakerState = (
  id: string,
  service: string,
  changeClientCircuitBreakerStateDto: ChangeClientCircuitBreakerStateDto,
  options?: SecondParameter<typeof http>
) => {
  return http<void>(
    {
      url: `/api/v1/clients/${id}/circuit-breakers/${service}`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: changeClientCircuitBreakerStateDto,
    },
    options
  );
};

export type SetClientCircuitBreakerStateMutationResult = NonNullable<
  Awaited<ReturnType<typeof setClientCircuitBreakerState>>
>;
export type SetClientCircuitBreakerStateMutationBody =
  ChangeClientCircuitBreakerStateDto;
export type SetClientCircuitBreakerStateMutationError = unknown;

export const useSetClientCircuitBreakerState = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setClientCircuitBreakerState>>,
    TError,
    { id: string; service: string; data: ChangeClientCircuitBreakerStateDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setClientCircuitBreakerState>>,
    { id: string; service: string; data: ChangeClientCircuitBreakerStateDto }
  > = (props) => {
    const { id, service, data } = props ?? {};

    return setClientCircuitBreakerState(id, service, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof setClientCircuitBreakerState>>,
    TError,
    { id: string; service: string; data: ChangeClientCircuitBreakerStateDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteClientUser = (
  id: string,
  userId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<void>(
    { url: `/api/v1/clients/${id}/users/${userId}`, method: "delete" },
    options
  );
};

export type DeleteClientUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteClientUser>>
>;

export type DeleteClientUserMutationError = unknown;

export const useDeleteClientUser = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteClientUser>>,
    TError,
    { id: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteClientUser>>,
    { id: string; userId: string }
  > = (props) => {
    const { id, userId } = props ?? {};

    return deleteClientUser(id, userId, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteClientUser>>,
    TError,
    { id: string; userId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getClients = (
  params?: GetClientsParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<ClientDto[]>(
    { url: `/api/v1/clients`, method: "get", params, signal },
    options
  );
};

export const getGetClientsQueryKey = (params?: GetClientsParams) => [
  `/api/v1/clients`,
  ...(params ? [params] : []),
];

export type GetClientsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClients>>
>;
export type GetClientsQueryError = unknown;

export const useGetClients = <
  TData = Awaited<ReturnType<typeof getClients>>,
  TError = unknown
>(
  params?: GetClientsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClients>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClientsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClients>>> = ({
    signal,
  }) => getClients(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getClients>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createClient = (
  clientCreateDto: ClientCreateDto,
  options?: SecondParameter<typeof http>
) => {
  return http<ClientDto>(
    {
      url: `/api/v1/clients`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: clientCreateDto,
    },
    options
  );
};

export type CreateClientMutationResult = NonNullable<
  Awaited<ReturnType<typeof createClient>>
>;
export type CreateClientMutationBody = ClientCreateDto;
export type CreateClientMutationError = unknown;

export const useCreateClient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createClient>>,
    TError,
    { data: ClientCreateDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createClient>>,
    { data: ClientCreateDto }
  > = (props) => {
    const { data } = props ?? {};

    return createClient(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createClient>>,
    TError,
    { data: ClientCreateDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const getClient = (
  id: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<ClientDto>(
    { url: `/api/v1/clients/${id}`, method: "get", signal },
    options
  );
};

export const getGetClientQueryKey = (id: string) => [`/api/v1/clients/${id}`];

export type GetClientQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClient>>
>;
export type GetClientQueryError = unknown;

export const useGetClient = <
  TData = Awaited<ReturnType<typeof getClient>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClient>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClientQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClient>>> = ({
    signal,
  }) => getClient(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getClient>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const updateClient = (
  id: string,
  clientUpdateDto: ClientUpdateDto,
  options?: SecondParameter<typeof http>
) => {
  return http<ClientDto>(
    {
      url: `/api/v1/clients/${id}`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: clientUpdateDto,
    },
    options
  );
};

export type UpdateClientMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateClient>>
>;
export type UpdateClientMutationBody = ClientUpdateDto;
export type UpdateClientMutationError = unknown;

export const useUpdateClient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateClient>>,
    TError,
    { id: string; data: ClientUpdateDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateClient>>,
    { id: string; data: ClientUpdateDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateClient(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateClient>>,
    TError,
    { id: string; data: ClientUpdateDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteClient = (
  id: string,
  options?: SecondParameter<typeof http>
) => {
  return http<void>(
    { url: `/api/v1/clients/${id}`, method: "delete" },
    options
  );
};

export type DeleteClientMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteClient>>
>;

export type DeleteClientMutationError = unknown;

export const useDeleteClient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteClient>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteClient>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteClient(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteClient>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const toggleScheduler = (
  id: string,
  schedulerToggleDto: SchedulerToggleDto,
  options?: SecondParameter<typeof http>
) => {
  return http<void>(
    {
      url: `/api/v1/clients/${id}/toggleScheduler`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: schedulerToggleDto,
    },
    options
  );
};

export type ToggleSchedulerMutationResult = NonNullable<
  Awaited<ReturnType<typeof toggleScheduler>>
>;
export type ToggleSchedulerMutationBody = SchedulerToggleDto;
export type ToggleSchedulerMutationError = unknown;

export const useToggleScheduler = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof toggleScheduler>>,
    TError,
    { id: string; data: SchedulerToggleDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof toggleScheduler>>,
    { id: string; data: SchedulerToggleDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return toggleScheduler(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof toggleScheduler>>,
    TError,
    { id: string; data: SchedulerToggleDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const getClientConfig = (
  id: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<ClientConfigDto>(
    { url: `/api/v1/clients/${id}/config`, method: "get", signal },
    options
  );
};

export const getGetClientConfigQueryKey = (id: string) => [
  `/api/v1/clients/${id}/config`,
];

export type GetClientConfigQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClientConfig>>
>;
export type GetClientConfigQueryError = unknown;

export const useGetClientConfig = <
  TData = Awaited<ReturnType<typeof getClientConfig>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClientConfig>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClientConfigQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientConfig>>> = ({
    signal,
  }) => getClientConfig(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getClientConfig>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getClientUsers = (
  id: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<UserDto[]>(
    { url: `/api/v1/clients/${id}/users`, method: "get", signal },
    options
  );
};

export const getGetClientUsersQueryKey = (id: string) => [
  `/api/v1/clients/${id}/users`,
];

export type GetClientUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClientUsers>>
>;
export type GetClientUsersQueryError = unknown;

export const useGetClientUsers = <
  TData = Awaited<ReturnType<typeof getClientUsers>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClientUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClientUsersQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientUsers>>> = ({
    signal,
  }) => getClientUsers(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getClientUsers>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const addClientUser = (
  id: string,
  addClientUserDto: AddClientUserDto,
  options?: SecondParameter<typeof http>
) => {
  return http<UserDto[]>(
    {
      url: `/api/v1/clients/${id}/users`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: addClientUserDto,
    },
    options
  );
};

export type AddClientUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof addClientUser>>
>;
export type AddClientUserMutationBody = AddClientUserDto;
export type AddClientUserMutationError = unknown;

export const useAddClientUser = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addClientUser>>,
    TError,
    { id: string; data: AddClientUserDto },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addClientUser>>,
    { id: string; data: AddClientUserDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return addClientUser(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof addClientUser>>,
    TError,
    { id: string; data: AddClientUserDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const getAvailableClientConfigs = (
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<string[]>(
    { url: `/api/v1/clients/available-configs`, method: "get", signal },
    options
  );
};

export const getGetAvailableClientConfigsQueryKey = () => [
  `/api/v1/clients/available-configs`,
];

export type GetAvailableClientConfigsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailableClientConfigs>>
>;
export type GetAvailableClientConfigsQueryError = unknown;

export const useGetAvailableClientConfigs = <
  TData = Awaited<ReturnType<typeof getAvailableClientConfigs>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAvailableClientConfigs>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof http>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAvailableClientConfigsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAvailableClientConfigs>>
  > = ({ signal }) => getAvailableClientConfigs(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAvailableClientConfigs>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const getClientStorageInfo = (
  id: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<ClientStorageInfoDto[]>(
    { url: `/api/v1/clients/${id}/storage-info`, method: "get", signal },
    options
  );
};

export const getGetClientStorageInfoQueryKey = (id: string) => [
  `/api/v1/clients/${id}/storage-info`,
];

export type GetClientStorageInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClientStorageInfo>>
>;
export type GetClientStorageInfoQueryError = unknown;

export const useGetClientStorageInfo = <
  TData = Awaited<ReturnType<typeof getClientStorageInfo>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClientStorageInfo>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetClientStorageInfoQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getClientStorageInfo>>
  > = ({ signal }) => getClientStorageInfo(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getClientStorageInfo>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getClientDbTableSize = (
  id: string,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<DbTableSizeDto[]>(
    { url: `/api/v1/clients/${id}/db-size`, method: "get", signal },
    options
  );
};

export const getGetClientDbTableSizeQueryKey = (id: string) => [
  `/api/v1/clients/${id}/db-size`,
];

export type GetClientDbTableSizeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClientDbTableSize>>
>;
export type GetClientDbTableSizeQueryError = unknown;

export const useGetClientDbTableSize = <
  TData = Awaited<ReturnType<typeof getClientDbTableSize>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClientDbTableSize>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetClientDbTableSizeQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getClientDbTableSize>>
  > = ({ signal }) => getClientDbTableSize(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getClientDbTableSize>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
