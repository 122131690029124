import React, {useState} from "react";
import {
  GetPohoda2ProductQueueMessagesParams,
  Pohoda2ProductQueueMessageDto,
} from "../../../api-client/model";
import {DEFAULT_FILTER} from "../../common/queue/SmartMessageQueue";
import {
  useGetPohoda2ProductQueueMessages,
  usePohoda2ProductQueueRetryMessage,
} from "../../../api-client/pohoda2";
import {MessageQueue} from "../../common/queue/MessageQueue";
import {Button} from "@mui/material";
import {XmlDialog, XmlDialogData} from "../../../components/XmlDialog";

type Props = {
  clientId: string,
  application: string,
};

export const Pohoda2ProductQueue: React.FC<Props> = ({clientId, application}) => {

  const [xmlDialogData, setXmlDialogData] = useState<XmlDialogData | null>();

  const [filter, setFilter] =
    useState<GetPohoda2ProductQueueMessagesParams>(DEFAULT_FILTER);
  const queueQuery = useGetPohoda2ProductQueueMessages(
    clientId,
    application,
    filter
  );

  const retryMutation = usePohoda2ProductQueueRetryMessage();

  function retry(message: Pohoda2ProductQueueMessageDto) {
    return retryMutation.mutateAsync({
      client: clientId,
      pohoda2Application: application,
      messageId: message.id,
    });
  }

  return (
    <>
      {xmlDialogData && <XmlDialog open={true} onClose={() => setXmlDialogData(null)} data={xmlDialogData} />}

      <MessageQueue
        title={'Fronta zpráv produktů'}
        clientId={clientId}
        messages={queueQuery.data?.items ?? []}
        refetch={queueQuery.refetch}
        isLoading={queueQuery.isLoading}
        count={queueQuery.data?.total}
        retryMessage={retry}
        filter={filter}
        onFilter={(filter) =>
          setFilter((current) => ({...current, ...filter}))
        }
        extraColumns={[
          {
            name: "Pohoda ID",
            render: (x) => x.pohodaId || "-",
            sx: {py: 0},
          },
          {
            name: "Request",
            render: (x) =>
              x.request && (
                <Button size="small"
                        onClick={() => setXmlDialogData({xml: x.request!, exportFilename: x.sourceId + '-productRequest'})}>
                  zobrazit
                </Button>
              ),
            sx: {py: 0},
          },
          {
            name: "Response",
            render: (x) =>
              x.response && (
                <Button size="small"
                        onClick={() => setXmlDialogData({xml: x.response!, exportFilename: x.sourceId + '-productResponse'})}>
                  zobrazit
                </Button>
              ),
            sx: {py: 0},
          },
        ]}
      ></MessageQueue>
    </>
  );
};
