import {useParams} from "react-router-dom";
import {OrionEdi} from "../apps/orionedi/OrionEdi";
import {SuperFaktura} from "../apps/superfaktura/SuperFaktura";
import {Emorfic} from "../apps/emorfic/Emorfic";
import {Navision} from "../apps/navision/Navision";
import React, {useContext} from "react";
import {ClientContext} from "./ClientManager";
import {BusinessCentral} from "../apps/businesscentral/BusinessCentral";
import {Lokia} from "../apps/lokia/Lokia";
import {Pohoda2} from "../apps/pohoda2/Pohoda2";
import {DefaultApp} from "../apps/defaultapp/DefaultApp";

export const ClientService = () => {
    const client = useContext(ClientContext);
    const params = useParams<{ service: string  }>();
    const service = client?.services.find(x => x.name.toLowerCase() === params.service);

    switch (service?.application) {
        case 'BusinessCentral':
            return <BusinessCentral service={service} />;
        case 'OrionEdi':
            return <OrionEdi />;
        case 'SuperFaktura':
            return <SuperFaktura />;
        case 'Emorfic':
            return <Emorfic service={service} />;
        case 'Lokia':
            return <Lokia service={service} />;
        case 'Navision':
            return <Navision />;
      case 'Pohoda2':
            return <Pohoda2 service={service} />;
        default:
            return service ? <DefaultApp service={service} /> : null;
    }
};
