import {Outbox} from "../common/outbox/Outbox";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {LokiaDocumentQueue} from "./document-queue/LokiaDocumentQueue";
import {LokiaNotificationQueue} from "./notification-queue/LokiaNotificationQueue";
import {LokiaProductQueue} from "./product-queue/LokiaProductQueue";
import {ServiceDto} from "../../api-client/model";
import {ApplicationTitle} from "../common/application/ApplicationTitle";

type Props = {
  service: ServiceDto;
};

export function Lokia({service}: Props) {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;
  const [activeTab, setActiveTab] = useState("documents");

  return (
    <>
      <ApplicationTitle service={service} />

      <TabContext value={activeTab}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
          >
            <Tab label="Documents" value="documents"></Tab>
            <Tab label="Notifications" value="notifications"></Tab>
            <Tab label="Products" value="products"></Tab>
            <Tab label="Outbox" value="outbox"></Tab>
          </Tabs>
        </Box>

        <TabPanel value="documents" sx={{px: 0}}>
          <LokiaDocumentQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="notifications" sx={{px: 0}}>
          <LokiaNotificationQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="products" sx={{px: 0}}>
          <LokiaProductQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="outbox" sx={{px: 0}}>
          <Outbox clientId={clientId} application={service.code} />
        </TabPanel>
      </TabContext>
    </>
  );
}
