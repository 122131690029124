import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import xmlFormat from "xml-formatter";

type Props = {
  open: boolean;
  onClose: () => void;
  data: XmlDialogData;
};

export type XmlDialogData = {
  xml: string;
  exportFilename: string;
};

export const XmlDialog: React.FC<Props> = ({ open, onClose, data }) => {

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>XML</DialogTitle>
      <DialogContent>
            <pre>
              <code style={{fontSize: 12}}>
                {xmlFormat(data.xml, {collapseContent: true, throwOnFailure: false})}
              </code>
            </pre>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'space-between'}}>
        <Button href={`data:text/xml;charset=utf-8,${encodeURIComponent(data.xml)}`}
                download={data.exportFilename + `.xml`}>Stáhnout</Button>
        <Button onClick={onClose}>Zavřít</Button>
      </DialogActions>
    </Dialog>
  );

};
