import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React from "react";
import {DbTableSizeDto} from "../api-client/model";
import {filesize} from "filesize";

export const DbTableSize: React.FC<{ tables: DbTableSizeDto[] }> = ({ tables }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Table Name</TableCell>
            <TableCell align="right">Rows</TableCell>
            <TableCell align="right">Data Size</TableCell>
            <TableCell align="right">Index Size</TableCell>
            <TableCell align="right">Total Size</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tables.map(x => (
            <TableRow key={x.tableName}>
              <TableCell>{x.tableName}</TableCell>
              <TableCell align="right">{x.rowCount}</TableCell>
              <TableCell align="right">{filesize(x.dataSize, { exponent: 3 })}</TableCell>
              <TableCell align="right">{filesize(x.indexSize, { exponent: 3 })}</TableCell>
              <TableCell align="right">{filesize(x.totalSize, { exponent: 3 })}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
