import {Outbox} from "../common/outbox/Outbox";
import {useParams} from "react-router-dom";
import React from "react";
import {Grid} from "@mui/material";
import {ServiceDto} from "../../api-client/model";
import {ApplicationTitle} from "../common/application/ApplicationTitle";

type Props = {
  service: ServiceDto;
};

export function DefaultApp({service}: Props) {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;

  return (
    <>
      <ApplicationTitle service={service} />

      <Grid container direction="column" spacing={5}>
        <Grid item>
          <Outbox clientId={clientId} application={service.code} />
        </Grid>
      </Grid>

    </>
  );
}
