/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  Pohoda2CustomerQueueMessageDto,
  Pohoda2CustomerQueueDto,
  GetPohoda2CustomerQueueMessagesParams,
  Pohoda2InvoiceQueueMessageDto,
  Pohoda2InvoiceQueueDto,
  GetPohoda2InvoiceQueueMessagesParams,
  Pohoda2OrderQueueMessageDto,
  Pohoda2OrderQueueDto,
  GetPohoda2OrderQueueMessagesParams,
  Pohoda2ProductQueueMessageDto,
  Pohoda2ProductQueueDto,
  GetPohoda2ProductQueueMessagesParams,
  Pohoda2TransactionQueueMessageDto,
  Pohoda2TransactionQueueDto,
  GetPohoda2TransactionQueueMessagesParams,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const pohoda2CustomerQueueRetryMessage = (
  client: string,
  pohoda2Application: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<Pohoda2CustomerQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/customer-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type Pohoda2CustomerQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof pohoda2CustomerQueueRetryMessage>>
>;

export type Pohoda2CustomerQueueRetryMessageMutationError = unknown;

export const usePohoda2CustomerQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pohoda2CustomerQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pohoda2CustomerQueueRetryMessage>>,
    { client: string; pohoda2Application: string; messageId: string }
  > = (props) => {
    const { client, pohoda2Application, messageId } = props ?? {};

    return pohoda2CustomerQueueRetryMessage(
      client,
      pohoda2Application,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof pohoda2CustomerQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getPohoda2CustomerQueueMessages = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2CustomerQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<Pohoda2CustomerQueueDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/customer-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPohoda2CustomerQueueMessagesQueryKey = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2CustomerQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${pohoda2Application}/customer-queue`,
  ...(params ? [params] : []),
];

export type GetPohoda2CustomerQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPohoda2CustomerQueueMessages>>
>;
export type GetPohoda2CustomerQueueMessagesQueryError = unknown;

export const useGetPohoda2CustomerQueueMessages = <
  TData = Awaited<ReturnType<typeof getPohoda2CustomerQueueMessages>>,
  TError = unknown
>(
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2CustomerQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPohoda2CustomerQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPohoda2CustomerQueueMessagesQueryKey(
      client,
      pohoda2Application,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPohoda2CustomerQueueMessages>>
  > = ({ signal }) =>
    getPohoda2CustomerQueueMessages(
      client,
      pohoda2Application,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getPohoda2CustomerQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && pohoda2Application),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const pohoda2InvoiceQueueRetryMessage = (
  client: string,
  pohoda2Application: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<Pohoda2InvoiceQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/invoice-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type Pohoda2InvoiceQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof pohoda2InvoiceQueueRetryMessage>>
>;

export type Pohoda2InvoiceQueueRetryMessageMutationError = unknown;

export const usePohoda2InvoiceQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pohoda2InvoiceQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pohoda2InvoiceQueueRetryMessage>>,
    { client: string; pohoda2Application: string; messageId: string }
  > = (props) => {
    const { client, pohoda2Application, messageId } = props ?? {};

    return pohoda2InvoiceQueueRetryMessage(
      client,
      pohoda2Application,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof pohoda2InvoiceQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getPohoda2InvoiceQueueMessages = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2InvoiceQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<Pohoda2InvoiceQueueDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/invoice-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPohoda2InvoiceQueueMessagesQueryKey = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2InvoiceQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${pohoda2Application}/invoice-queue`,
  ...(params ? [params] : []),
];

export type GetPohoda2InvoiceQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPohoda2InvoiceQueueMessages>>
>;
export type GetPohoda2InvoiceQueueMessagesQueryError = unknown;

export const useGetPohoda2InvoiceQueueMessages = <
  TData = Awaited<ReturnType<typeof getPohoda2InvoiceQueueMessages>>,
  TError = unknown
>(
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2InvoiceQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPohoda2InvoiceQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPohoda2InvoiceQueueMessagesQueryKey(
      client,
      pohoda2Application,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPohoda2InvoiceQueueMessages>>
  > = ({ signal }) =>
    getPohoda2InvoiceQueueMessages(
      client,
      pohoda2Application,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getPohoda2InvoiceQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && pohoda2Application),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const pohoda2OrderQueueRetryMessage = (
  client: string,
  pohoda2Application: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<Pohoda2OrderQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/order-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type Pohoda2OrderQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof pohoda2OrderQueueRetryMessage>>
>;

export type Pohoda2OrderQueueRetryMessageMutationError = unknown;

export const usePohoda2OrderQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pohoda2OrderQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pohoda2OrderQueueRetryMessage>>,
    { client: string; pohoda2Application: string; messageId: string }
  > = (props) => {
    const { client, pohoda2Application, messageId } = props ?? {};

    return pohoda2OrderQueueRetryMessage(
      client,
      pohoda2Application,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof pohoda2OrderQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getPohoda2OrderQueueMessages = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2OrderQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<Pohoda2OrderQueueDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/order-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPohoda2OrderQueueMessagesQueryKey = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2OrderQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${pohoda2Application}/order-queue`,
  ...(params ? [params] : []),
];

export type GetPohoda2OrderQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPohoda2OrderQueueMessages>>
>;
export type GetPohoda2OrderQueueMessagesQueryError = unknown;

export const useGetPohoda2OrderQueueMessages = <
  TData = Awaited<ReturnType<typeof getPohoda2OrderQueueMessages>>,
  TError = unknown
>(
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2OrderQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPohoda2OrderQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPohoda2OrderQueueMessagesQueryKey(client, pohoda2Application, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPohoda2OrderQueueMessages>>
  > = ({ signal }) =>
    getPohoda2OrderQueueMessages(
      client,
      pohoda2Application,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getPohoda2OrderQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && pohoda2Application),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const pohoda2ProductQueueRetryMessage = (
  client: string,
  pohoda2Application: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<Pohoda2ProductQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/product-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type Pohoda2ProductQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof pohoda2ProductQueueRetryMessage>>
>;

export type Pohoda2ProductQueueRetryMessageMutationError = unknown;

export const usePohoda2ProductQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pohoda2ProductQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pohoda2ProductQueueRetryMessage>>,
    { client: string; pohoda2Application: string; messageId: string }
  > = (props) => {
    const { client, pohoda2Application, messageId } = props ?? {};

    return pohoda2ProductQueueRetryMessage(
      client,
      pohoda2Application,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof pohoda2ProductQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getPohoda2ProductQueueMessages = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2ProductQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<Pohoda2ProductQueueDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/product-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPohoda2ProductQueueMessagesQueryKey = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2ProductQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${pohoda2Application}/product-queue`,
  ...(params ? [params] : []),
];

export type GetPohoda2ProductQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPohoda2ProductQueueMessages>>
>;
export type GetPohoda2ProductQueueMessagesQueryError = unknown;

export const useGetPohoda2ProductQueueMessages = <
  TData = Awaited<ReturnType<typeof getPohoda2ProductQueueMessages>>,
  TError = unknown
>(
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2ProductQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPohoda2ProductQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPohoda2ProductQueueMessagesQueryKey(
      client,
      pohoda2Application,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPohoda2ProductQueueMessages>>
  > = ({ signal }) =>
    getPohoda2ProductQueueMessages(
      client,
      pohoda2Application,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getPohoda2ProductQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && pohoda2Application),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const pohoda2TransactionQueueRetryMessage = (
  client: string,
  pohoda2Application: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<Pohoda2TransactionQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/transaction-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type Pohoda2TransactionQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof pohoda2TransactionQueueRetryMessage>>
>;

export type Pohoda2TransactionQueueRetryMessageMutationError = unknown;

export const usePohoda2TransactionQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pohoda2TransactionQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pohoda2TransactionQueueRetryMessage>>,
    { client: string; pohoda2Application: string; messageId: string }
  > = (props) => {
    const { client, pohoda2Application, messageId } = props ?? {};

    return pohoda2TransactionQueueRetryMessage(
      client,
      pohoda2Application,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof pohoda2TransactionQueueRetryMessage>>,
    TError,
    { client: string; pohoda2Application: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getPohoda2TransactionQueueMessages = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2TransactionQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<Pohoda2TransactionQueueDto>(
    {
      url: `/api/v1/clients/${client}/${pohoda2Application}/transaction-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPohoda2TransactionQueueMessagesQueryKey = (
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2TransactionQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${pohoda2Application}/transaction-queue`,
  ...(params ? [params] : []),
];

export type GetPohoda2TransactionQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPohoda2TransactionQueueMessages>>
>;
export type GetPohoda2TransactionQueueMessagesQueryError = unknown;

export const useGetPohoda2TransactionQueueMessages = <
  TData = Awaited<ReturnType<typeof getPohoda2TransactionQueueMessages>>,
  TError = unknown
>(
  client: string,
  pohoda2Application: string,
  params?: GetPohoda2TransactionQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPohoda2TransactionQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPohoda2TransactionQueueMessagesQueryKey(
      client,
      pohoda2Application,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPohoda2TransactionQueueMessages>>
  > = ({ signal }) =>
    getPohoda2TransactionQueueMessages(
      client,
      pohoda2Application,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getPohoda2TransactionQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && pohoda2Application),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
