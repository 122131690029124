import React, { useEffect, useState } from "react";
import {GetQueueParams, SmartQueueMessageDto, TypeQueueMessageDto} from "../../../api-client/model";
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel, InputLabel, MenuItem,
  Paper,
  Radio,
  RadioGroup, Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { dateTimeFormat } from "../../../utils/date";
import { ConfirmDialog } from "../../../ui/ConfirmDialog";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import {useGetQueue} from "../../../api-client/queue";
import {TextExpand} from "../../../components/TextExpand";
import {JsonDialog, JsonDialogData} from "../../../components/JsonDialog";

export const DEFAULT_FILTER: GetQueueParams = {
  status: "all",
  page: 0,
  size: 10,
};

interface Column<TypeQueueMessageDto> {
  name: string;
  render: (item: TypeQueueMessageDto) => React.ReactNode;
  sx?: SxProps;
}

type Props = {
  clientId: string;
  application: string;
  title?: string;
};

export function TypeMessageQueue({
  title,
  clientId,
  application,
}: Props) {
  const [messageToRetry, setMessageToRetry] = useState<TypeQueueMessageDto | null>(null);
  const [filter, setFilter] = useState<GetQueueParams>(DEFAULT_FILTER);
  const [jsonDialogData, setJsonDialogData] = useState<JsonDialogData | null>();


  const queueQuery = useGetQueue(clientId, application, filter);

  async function retry(message: TypeQueueMessageDto) {
    // await retryMessage(message);
    // setMessageToRetry(null);
    // await refetch();
  }

  return (
    <>
      {jsonDialogData && <JsonDialog open={true} onClose={() => setJsonDialogData(null)} data={jsonDialogData} />}

      <Typography variant="h6" gutterBottom>
        {title ?? 'Message queue'}
      </Typography>

      <Paper>
        <Toolbar sx={{ py: 3 }}>
          <FormControl size="small" sx={{ width: 200, mr: 3 }}>
            <FormLabel>Type</FormLabel>

            <Select
              value={filter.type}
              onChange={e => setFilter(x => ({ ...x, type: e.target.value }))}
            >
              <MenuItem>-</MenuItem>
              {queueQuery.data?.types?.map(type => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Status</FormLabel>
            <RadioGroup
              row
              name="status"
              value={filter.status}
              onChange={(e, status) => {
                setFilter(x => ({
                  ...x,
                  status,
                  page: 0,
                }));
              }}
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel
                value="waiting"
                control={<Radio />}
                label="Waiting"
              />
              <FormControlLabel
                value="failed"
                control={<Radio />}
                label="Failed"
              />
              <FormControlLabel
                value="processed"
                control={<Radio />}
                label="Processed"
              />
            </RadioGroup>
          </FormControl>
        </Toolbar>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Entity ID</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Processed At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Error</TableCell>
                <TableCell>Source</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {queueQuery.isLoading &&
                new Array(filter.size ?? 0).fill(0).map((_, i) => (
                  <TableRow key={i} sx={{ height: 34.3 }}>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                ))}
              {queueQuery.data?.items.map((message) => (
                <TableRow key={message.id}>
                  <TableCell>{message.type}</TableCell>
                  <TableCell>{(message.data as any)?.id}</TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Button size="small"
                            onClick={() => setJsonDialogData({json: message.data!, exportFilename: message.id + '-data'})}>
                      zobrazit
                    </Button>
                  </TableCell>
                  <TableCell>{dateTimeFormat(message.occurredOn)}</TableCell>
                  <TableCell>
                    {message.processedDate
                      ? dateTimeFormat(message.processedDate)
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {message.processedDate ? (
                      <Chip
                        sx={{ height: 20, lineHeight: 1 }}
                        size="small"
                        label={message.failed ? "Error" : "Processed"}
                        color={message.failed ? "error" : "success"}
                      />
                    ) : (
                      <Chip
                        sx={{ height: 20, lineHeight: 1 }}
                        size="small"
                        label="Waiting"
                        color="primary"
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 500 }}>
                    <TextExpand text={message.reason || "-"} maxLength={255}></TextExpand>
                  </TableCell>
                  <TableCell>{message.sourceSystem || '-'}</TableCell>
                  <TableCell sx={{ py: 0, textAlign: "right" }}>
                    {message.failed && (
                      <Button
                        size="small"
                        onClick={() => setMessageToRetry(message)}
                      >
                        Opakovat
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={queueQuery.data?.total ?? -1}
          page={filter.page ?? 0}
          onPageChange={(e, page) => setFilter(x => ({ ...x, page }))}
          rowsPerPage={filter.size ?? -1}
          onRowsPerPageChange={(e) =>
            setFilter(x => ({
              ...x,
              size: parseInt(e.target.value),
              page: 0,
            }))
          }
        />
      </Paper>

      <ConfirmDialog
        open={messageToRetry != null}
        title="Opakované zpracování"
        onCancel={() => setMessageToRetry(null)}
        onConfirm={() => retry(messageToRetry!)}
      >
        Zařadit zprávu k opakovanému zpracování?
      </ConfirmDialog>
    </>
  );
}
